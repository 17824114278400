/* 渡すことを考えてcss変数はすべて持ってくるor実数に変換 */
@custom-media --media-md only screen and (min-width: 769px);

.cropModal {
    --radius-lg: 20px;
    --radius-sm: 10px;
    --color-primary: rgb(0, 191, 165);
    --color-secondary: #EE9264;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    touch-action: none;
    opacity: 0;
    transition: opacity .2s;
    pointer-events: none;

    &[aria-hidden="false"] {
        opacity: 1;
        pointer-events: all;
    }
}

.cropModalBg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgb(0 0 0 / 50%);
    cursor: pointer;
}

.cropModalContents {
    max-width: 1024px;
    max-height: 96vh;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    border: none;
    background-color: #FFF;
    overflow: hidden;
    border-top-left-radius: var(--radius-sm);
    border-top-right-radius: var(--radius-sm);
    border-bottom-left-radius: var(--radius-sm);
    border-bottom-right-radius: var(--radius-sm);

    @media (--media-md) {
        border-top-left-radius: var(--radius-lg);
        border-top-right-radius: var(--radius-lg);
        border-bottom-left-radius: var(--radius-lg);
        border-bottom-right-radius: var(--radius-lg);
    }
}

.cropModalHeader {
    border-top-left-radius: var(--radius-sm);
    border-top-right-radius: var(--radius-sm);
    font-size: 20px;
    letter-spacing: 0.05em;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    color: #FFF;
    background-color: var(--color-primary);
}

.cropperButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    font-size: 16px;
    color: var(--color-primary);
    margin-top: 10px;

    @media (--media-md) {
        font-size: 18px;
    }
}

.cropperRotateButton {
    aspect-ratio: 1 / 1;
    width: 50px;
    height: auto;
    border: none;
    background-color: #fff;

    @media (--media-md) {
        width: 50px;
    }

    & > img {
        width: 100%;
        height: 100%;
    }
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: none;
    border-radius: 100px;
    color: #FFF;
    background-color: var(--color-secondary);
    position: relative;
    cursor: pointer;
    height: 42px;
    letter-spacing: 0.05em;
    min-width: 200px;
    margin: 10px  auto 16px auto;

    @media (--media-md) {
        min-width: 300px;
        height: 50px;
    }

    &::before,
    &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
        z-index: unset;
    }

    &::before {
        width: 100%;
        height: 50%;
        background-color: rgb(0 0 0 / 5%);
    }

    &::after {
        opacity: 0;
        width: 100%;
        height: 100%;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        transition: opacity 0.2s ease;
        background-color: rgb(0 0 0 / 10%);
    }

    @media (hover: hover) {
        &:hover::after {
            opacity: 1;
        }
    }

    & .nextIcon {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }
}

.modalFooter {
    /*position: absolute;*/
    /*bottom: 0;*/
    width: 100%;
    z-index: unset;
    background-color: #fff;
}

