@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500;700&display=swap");

* {
  font-size: 16px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  //font-family: sans-serif;
  font-family: "M PLUS Rounded 1c", sans-serif;
  color: #333;
}

body, html {
  min-height: 100vh;

  #root {
    min-height: 100vh;
  }
}

body {
  background-image: url(./images/bg.svg);
  background-repeat: repeat-x;
  background-color: #EFEFEF;
  background-attachment: fixed;
}

a {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


.mt0 {
  margin-top: 0 !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pr0 {
  padding-right: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}


@for $i from 1 through 100 {
  .mt#{$i} {
    margin-top: #{$i}px !important;
  }
  .pt#{$i} {
    padding-top: #{$i}px !important;
  }
  .pl#{$i} {
    padding-left: #{$i}px !important;
  }
  .pr#{$i} {
    padding-right: #{$i}px !important;
  }
  .mb#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .pb#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pd#{$i} {
    padding: #{$i}px !important;
  }
  .ml#{$i} {
    margin-left: #{$i}px !important;
  }
  .mr#{$i} {
    margin-right: #{$i}px !important;
  }
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}